import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disable-button-with-spinner"
export default class extends Controller {
  connect() {
    console.log('Disable Button With Spinner connected!')
  }

  disableWithSpinner(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const form = button.closest('form');
    const icon = button.querySelector('.button-icon');
    const buttonText = button.querySelector('.button-text');

    button.disabled = true;
    icon.classList.remove('fa-file-pen');
    icon.classList.add('fa-sync', 'fa-spin', 'mr-2');
    buttonText.textContent = 'Generating Quick Text ...';

    form.action = button.getAttribute('formaction');
    form.submit();
  }
}
