import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-advice-document"
export default class extends Controller {
  static targets = ["adviceDocumentRequired",
                    "adviceDocumentInstructions"]

  connect() {
    console.log("ShowHideAdviceDocument connected!")

    this.handleAdviceDocumentRequiredChange();
    this.element.addEventListener("change", () => this.handleAdviceDocumentRequiredChange());    
  }

  handleAdviceDocumentRequiredChange() {
    const adviceDocumentRequiredValue = this.adviceDocumentRequiredTarget.value;

    console.log(`Advice Document Required: ${adviceDocumentRequiredValue}`);

    // Perform logic based on the adviceDocumentRequiredValue 
    if (adviceDocumentRequiredValue === 'yes') {
      // Show
      this.adviceDocumentInstructionsTarget.classList.remove("hidden");
    } else {
      // Hide
      this.adviceDocumentInstructionsTarget.classList.add("hidden");
    }
  }  
}
