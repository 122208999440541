import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-fact-find"
export default class extends Controller {

  static targets = [ "factFindRequired", "factFindDetails" ]

  connect() {
    console.log("ShowHideFactFindController connected!");

    this.handleFactFindRequiredChange();
    this.element.addEventListener("change", () => this.handleFactFindRequiredChange());    
  }

  handleFactFindRequiredChange() {
    
    const factFindRequiredValue = this.factFindRequiredTarget.value;

    console.log(`Fact Find Required: ${factFindRequiredValue}`);

    // Perform logic based on the factFindRequiredValue
    if (factFindRequiredValue === 'yes') {
      this.factFindDetailsTarget.classList.remove("hidden");
    } else {
      this.factFindDetailsTarget.classList.add("hidden");
    }
    
  }

}
