import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-existing-strategy"
export default class extends Controller {
  static targets = ["retainReplace",
                    "retained",
                    "replaced",
                    "implementationRequired",
                    "implementation",
                    "recommendationAccepted",
                    "recommendationAcceptedDetails"
                    ]

  connect() {
    console.log("ExistingStrategyController connected!");

    this.handleRecommendationChange();
    this.element.addEventListener("change", () => this.handleRecommendationChange());

    this.handleImplementationChange();
    this.element.addEventListener("change", () => this.handleImplementationChange());

    this.handleRecommendationAcceptedChange();
    this.element.addEventListener("change", () => this.handleRecommendationAcceptedChange());
  }

  handleRecommendationChange() {
    if (this.hasRetainReplaceTarget && this.hasRetainedTarget && this.hasReplacedTarget) {
      const retainReplaceValue = this.retainReplaceTarget.value;

      console.log(`Recommendation: ${retainReplaceValue}`);

      // Perform logic based on the retainReplaceValue
      if (retainReplaceValue === "retain") {
        this.retainedTarget.classList.remove("hidden");
        this.replacedTarget.classList.add("hidden");
      } else if (retainReplaceValue === "replace") {
        this.retainedTarget.classList.add("hidden");
        this.replacedTarget.classList.remove("hidden");
      } else {
        this.retainedTarget.classList.add("hidden");
        this.replacedTarget.classList.add("hidden");
      }
    }
  }

  handleImplementationChange() {
    if (this.hasImplementationRequiredTarget && this.hasImplementationTarget) {
      const implementationRequiredValue = this.implementationRequiredTarget.value;

      console.log(`Implementation Required: ${implementationRequiredValue}`);

      // Perform logic based on the checkbox values
      if (implementationRequiredValue === 'true') {
        // Show
        this.implementationTarget.classList.remove("hidden");
      } else {
        // Hide
        this.implementationTarget.classList.add("hidden");
      }
    }
  }

  handleRecommendationAcceptedChange() {
    if (this.hasRecommendationAcceptedTarget && this.hasRecommendationAcceptedDetailsTarget) {
      const recommendationAcceptedValue = this.recommendationAcceptedTarget.value;

      console.log(`Recommendation Accepted: ${recommendationAcceptedValue}`);

      // Perform logic based on the checkbox values
      if (recommendationAcceptedValue === 'yes_with_changes') {
        // Show
        this.recommendationAcceptedDetailsTarget.classList.remove("hidden");
      } else {
        // Hide
        this.recommendationAcceptedDetailsTarget.classList.add("hidden");
      }
    }
  }

}
