import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-existing-product"
export default class extends Controller {
  static values = { productType: String }

  static targets = ["retain_replace",
                    "ProposedSuper",
                    "ProposedRetirement",
                    "ProposedInvestment",
                    "Retained",
                    "Replaced",

                    "implementation_required",
                    "Implementation",

                    "recommendationAccepted",
                    "recommendationAcceptedDetails"
                   ]

  connect() {
    console.log("ExistingProductController connected!");

    this.handleRecommendationChange();
    this.element.addEventListener("change", () => this.handleRecommendationChange());    

    this.handleImplementationCheckboxChange();
    this.element.addEventListener("change", () => this.handleImplementationCheckboxChange());

    this.handleRecommendationAcceptedChange();
    this.element.addEventListener("change", () => this.handleRecommendationAcceptedChange());    
  }

  handleRecommendationChange() {
    if (this.hasRetain_replaceTarget && this.hasRetainedTarget && this.hasReplacedTarget && this.hasProposedSuperTarget && this.hasProposedRetirementTarget && this.hasProposedInvestmentTarget) {

      const selectedRecommendationValue = this.retain_replaceTarget.value;
      const selectedProductTypeValue = this.productTypeValue;

      console.log(`Recommendation: ${selectedRecommendationValue}`);
      console.log(`Product Type: ${selectedProductTypeValue}`);

      // Show / hide the retained / replaced fields based on the recommendation
      if (selectedRecommendationValue === "retained") {
        this.RetainedTarget.classList.remove("hidden");
        this.ReplacedTarget.classList.add("hidden");
    
      } else if (selectedRecommendationValue === "replaced") {
        this.RetainedTarget.classList.add("hidden");
        this.ReplacedTarget.classList.remove("hidden");
    
      } else {
        this.RetainedTarget.classList.add("hidden");
        this.ReplacedTarget.classList.add("hidden");
      }   

      // Hide all proposed balance fields if selectedRecommendationValue is empty
      if (selectedRecommendationValue === '') {
        this.ProposedSuperTarget.classList.add("hidden");
        this.ProposedRetirementTarget.classList.add("hidden");
        this.ProposedInvestmentTarget.classList.add("hidden");
      }    

      // Show / hide the proposed balance fields based on the product type
      if (selectedRecommendationValue === "retained" || selectedRecommendationValue === "replaced") {
        if (selectedProductTypeValue === "Superannuation") {
          this.ProposedSuperTarget.classList.remove("hidden");
          this.ProposedRetirementTarget.classList.add("hidden");
          this.ProposedInvestmentTarget.classList.add("hidden");
        } else if (selectedProductTypeValue === "Retirement") {
          this.ProposedSuperTarget.classList.add("hidden");
          this.ProposedRetirementTarget.classList.remove("hidden");
          this.ProposedInvestmentTarget.classList.add("hidden");
        } else if (selectedProductTypeValue === "Investment") {
          this.ProposedSuperTarget.classList.add("hidden");
          this.ProposedRetirementTarget.classList.add("hidden");
          this.ProposedInvestmentTarget.classList.remove("hidden");
        } else {
          this.ProposedSuperTarget.classList.add("hidden");
          this.ProposedRetirementTarget.classList.add("hidden");
          this.ProposedInvestmentTarget.classList.add("hidden");
        }
      }
    }
  }

  handleImplementationCheckboxChange() {
    if (this.hasImplementation_requiredTarget && this.hasImplementationTarget) {

      const implementation_requiredValue = this.implementation_requiredTarget.value;

      console.log(`Implementation Required: ${implementation_requiredValue}`);

      // Perform logic based on the checkbox values
      if (implementation_requiredValue === "true") {
        // Show 
        this.ImplementationTarget.classList.remove("hidden");
      } else {
        // Hide 
        this.ImplementationTarget.classList.add("hidden");
      }
    }
  }  

  handleRecommendationAcceptedChange() {
    if (this.hasRecommendationAcceptedTarget && this.hasRecommendationAcceptedDetailsTarget) {

      const recommendationAcceptedValue = this.recommendationAcceptedTarget.value;

      console.log(`Recommendation Accepted: ${recommendationAcceptedValue}`);

      // Perform logic based on the checkbox values
      if (recommendationAcceptedValue === 'yes_with_changes') {
        // Show
        this.recommendationAcceptedDetailsTarget.classList.remove("hidden");
      } else {
        // Hide
        this.recommendationAcceptedDetailsTarget.classList.add("hidden");
      }
    }
  }  
}  

